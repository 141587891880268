





































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'

// @ts-ignore
import StarRating from 'vue-star-rating'

const savedPosition = {
  x: 0,
  y: 0,
}

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loading', 'fatalError', 'receipts_data', 'receipt_items', 'brands',
      'filter', 'merchants', 'meta', 'merchant_categories', 'summ', 'success_summ',
    ]),
    ...mapGetters(STORE_KEY, [
      'summary',
    ]),
  },
  components: {
    TitleBar,
  },
})
export default class ReceiptsModule extends Vue {

  public receipt: any = false
  public showModal: any = false
  public filter!: any
  public selectedId: number | null = null
  public for_outlets: any = null

  get titleStack() {
    return [
      'Список поступивших чеков',
    ]
  }

  public selectReceipt(id: any) {
    this.selectedId = id
    this.$store.dispatch(`${STORE_KEY}/loadReceipt`, id)
    this.$nextTick(() => {
      this.receipt = true
    })
  }

  public onPageChange(page: string) {
    this.$router.replace({
      query: { ...this.$route.query, page },
    })
  }

  public setFilter(filter: any) {
    this.$router.replace({
      query: { ...this.$route.query, ...this.filter },
    })
    this.showModal = false
  }

  public resetFilter() {
    this.$store.dispatch(`${STORE_KEY}/resetFilterState`)
    this.showModal = false
    this.handleParamsChange()
  }

  @Watch('$route.query')
  public handleParamsChange() {
    if (this.for_outlets !== null) {
      this.$store.dispatch(`${STORE_KEY}/loadData`, {
        page: this.$route.query.page,
        outlets: this.for_outlets,
      })
    }
    else {
      this.$store.dispatch(`${STORE_KEY}/loadData`, {
        page: this.$route.query.page,
        outlets: null,
      })
    }
  }

  public initModule() {
    this.$store.dispatch(`${STORE_KEY}/loadBrands`)
    this.$store.dispatch(`${STORE_KEY}/loadMerchants`)
    this.$store.dispatch(`${STORE_KEY}/loadMerchantCategories`)
  }

  // ------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }

  public mounted() {
    if ('for_outlets' in this.$route.params) {
      this.for_outlets = this.$route.params.for_outlets
    }

    this.initModule()
    this.handleParamsChange()
  }


}
