import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import moment from 'moment'

interface ReportReceipt {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;

  receipts_data: any;
  receipt_items: any;
  filter: any;
  brands: any[];
  merchants: any[];
  merchant_categories: any[];
  meta: any;
  summ: any;
  success_summ: any;
}

const $state: ReportReceipt = {
  loading: false,
  loaded: false,
  fatalError: false,

  receipts_data: null,
  receipt_items: null,
  brands: [],
  merchants: [],
  merchant_categories: [],
  filter: {
    city_id: null,
    merchant_category_id: null,
    brands: [],
    merchants: [],
    registration_type: '',
    datetime_from: null,
    datetime_to: null,
    customer_name: null,
  },
  meta: {
    total_count: 0,
    total_sum: 0,
    per_page: 0,
    page: 0,
  },
  summ: null,
  success_summ: null,
}

export const $actions: ActionTree<ReportReceipt, RootState> = {
  loadData({ state, commit, getters, dispatch }, { page, outlets }) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      let params: any = {}

      if (state.filter) {
        // eslint-disable-next-line no-self-assign
        state.filter = state.filter

        params = {
          city_id: state.filter.city_id,
          category_id: state.filter.merchant_category_id,
          brands: JSON.stringify(state.filter.brands.map((i: any) => i.id)),
          merchants: JSON.stringify(state.filter.merchants.map((i: any) => i.id)),
          registration_type: state.filter.registration_type,
          datetime_from: state.filter.datetime_from,
          datetime_to: state.filter.datetime_to,
          customer_name: state.filter.customer_name,
        }
      }

      if (outlets) {
        params.outlets = JSON.stringify(outlets)
      }

      params.page = page
      params.per_page = 20

      commit('START_LOADING')

      vm.$http
        .get('/admins/rest/v1/receipts', { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')

          resolve(null)
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },
  loadBrands({ commit, state }) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/brands`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_BRANDS', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },
  loadMerchants({ commit, state }) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/merchants`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_MERCHANTS', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },
  loadReceipt({ commit, getters, dispatch }, id) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {

      commit('START_LOADING')

      vm.$http
        .get(`/admins/rest/v1/receipts/${id}`)
        .then((response: AxiosResponse) => {
          commit('SET_RECEIPT', { items: response.data.receipt })
          commit('LOADED_SUCCESS')

          resolve(null)
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },

  loadMerchantCategories({ commit, state }, predefined) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/merchant_categories`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_MERCHANT_CATEGORIES', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

  resetFilterState({ commit, dispatch }, predefined) {
    commit('SET_FILTER_DEFAULTS')
    // dispatch(`loadData`)
  },

}
const compare: any = ((a: any, b: any) => {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
})
export const $mutations: MutationTree<ReportReceipt> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_ITEMS_LIST(state, { receipts, meta, summ, success_summ }) {
    state.receipts_data = receipts
    state.meta = meta
    state.summ = summ
    state.success_summ = success_summ
  },
  SET_RECEIPT(state, items) {
    state.receipt_items = items
  },
  SET_BRANDS: (state, { brands }) => {
    state.brands = brands.sort(compare)
  },
  SET_MERCHANT_CATEGORIES: (state, { merchant_categories }) => {
    state.merchant_categories = merchant_categories.sort(compare)
  },
  SET_MERCHANTS: (state, { merchants }) => {
    state.merchants = merchants.sort(compare)
  },
  SET_FILTER_DEFAULTS: (state) => {
    state.filter = {
      city_id: null,
      merchant_category_id: null,
      brands: [],
      merchants: [],
      registration_type: 'all',
      datetime_from: moment().startOf('year').format('YYYY-MM-DD'),
      datetime_to: moment().format('YYYY-MM-DD'),
    }
  },
}

export const $getters: GetterTree<ReportReceipt, RootState> = {
  summary(state) {
    return {
      count: state.receipts_data ? state.receipts_data.length : 0,
      sum: state.summ ? state.summ.reduce((sum: any, item: any) => sum + item.total_sum, 0) : 0,
    }
  },
}

export const STORE_KEY = '$_report_receipt'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
